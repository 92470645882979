import React, { FC } from 'react';
import { Link } from 'gatsby';

export const LogoOrName: FC<{ businessLogo?: string, name?: string }> = ({
  businessLogo,
  name,
  className,
}) => (
  <>
    {businessLogo && (
      <Link className={className} to="/">
        <img
          src={businessLogo}
          className={`
            object-contain
            h-10 md:h-12 max-w-32
            md:max-w-48
            lg:max-w-60
          `}
          alt=""
        />
      </Link>
    )}
    {!businessLogo && (
      <Link to="/" className="font-title font-bold text-base lg:text-xl">
        {name}
      </Link>
    )}
  </>
);
