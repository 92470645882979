import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ThemeContext } from '../ThemeContext';
import { LogoOrName } from '../LogoOrName/LogoOrName';

import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fireClickAnalyticsEvent } from '@websites/utils';

import * as styles from './MenuHeaderToggleButton.module.css'

export const MenuHeaderToggleButton = ({ sections, yellowBusiness }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { text, bg, isSharp } = useContext(ThemeContext);
  return (
    <div className="font-sans text-sm">
      <div>
        <button
          type="button"
          onClick={() => {
            setMenuIsOpen(true);
            fireClickAnalyticsEvent('button_click', 'menu_open');
          }}
        >
          <FontAwesomeIcon size="lg" icon={faBars} />
        </button>
      </div>
      <div
        className={`
          fixed top-0 right-0 w-full h-full opacity-100 z-50
          ${text('highlight-light')} ${bg('highlight-dark')} 
          ${styles.menu} ${menuIsOpen && styles.menuOpen}
        `}
      >
        <div className="w-full px-2 py-16 relative">
          <div className="max-w-32 mx-auto flex justify-center">
            <LogoOrName
              businessLogo={yellowBusiness?.logo || ''}
              name={yellowBusiness?.name || ''}
            />
          </div>
          <button
            type="button"
            className="absolute right-1 top-1 text-2xl"
            title="close"
            onClick={() => {
              setMenuIsOpen(false);
              fireClickAnalyticsEvent('button_click', 'menu_close');
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="flex flex-col items-center text-center font-bold">
          {sections && Object.values(sections).filter(Boolean).map((section, index) => {
            return (
              <Link
                key={index}
                to={section?.link || '/'}
                className={`
                  hover:${bg('highlight-dark')}
                  w-full py-4 hover:${text('highlight-light')}
                  ${isSharp ? 'text-lg uppercase tracking-widest' : 'text-xl'
                }`}
                onClick={() => {
                  setMenuIsOpen(false);
                  fireClickAnalyticsEvent('link_click', section?.name);
                }}
              >
                <span>{section?.name}</span>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  );
};
