import React from 'react';
import { Link } from 'gatsby';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Headlines } from '../..';
import { CoverImage } from '../CoverImage';

import { ThemeContext } from '../ThemeContext';

import { fireClickAnalyticsEvent } from '@websites/utils';

import * as styles from './SectionHeroBanner.module.css';

export const SectionHeroBanner = ({ yellowBusiness, isEcommerce }) => {
  const { text, bg } = React.useContext(ThemeContext);

  return (
    <div className={`relative w-full ${bg('base-dark')} font-title overflow-hidden`} style={{
      margin: '0 calc(50% - 50vw)',
      width: '100vw',
    }}>
      <Headlines
        className="max-width-wrapper absolute inset-0 z-10"
        headline={yellowBusiness?.website?.headline}
        subHeadline={yellowBusiness?.website?.subHeadline}
      />
      <CoverImage
        className="inset-0 z-0"
        yellowBusiness={yellowBusiness}
      />
      {isEcommerce && (
        <div
          className={`
            ${text('highlight-light')}
            ${styles.shopNowButton}
            absolute z-20 left-0 right-0 w-full flex justify-center
          `}
        >
          <Link
            to="/#catalog"
            onClick={() => fireClickAnalyticsEvent('link_click', 'catalog')}
            className="font-sans text-xs lg:text-sm uppercase tracking-widest flex space-x-2 items-center hover:underline font-bold"
          >
            <span>shop now</span>
            <FontAwesomeIcon icon={faChevronDown} className="animate-bounce" />
          </Link>
        </div>
      )}
    </div>
  )
}
